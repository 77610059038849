import React from 'react'
import Navbar from '../../../components/navbar/Navbar'

const Azienda = () => {
  return (
    <div className='home-out'>
        <Navbar user={true} />
    </div>
  )
}

export default Azienda